export function updateStats(list: FileNode[], containerNumber: number) {
  // 文件夹数量
  const folder = list.length

  // 商品总数
  const total = list
    .flatMap((item) => item.children)
    .reduce((sum, item) => sum + item.count, 0)

  // 预计可排版文件
  const container = Math.ceil(total / containerNumber) * 2

  // 预计卡面会冗余的数量
  const remainder = total % containerNumber

  return { folder, total, container, remainder }
}

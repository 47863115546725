import { drawLayout } from './drawLayout'
import { useScale } from './useScale'

/**
 * 绘制排版
 * @param ctx 绘制上下文
 * @param op 绘制参数
 * @param containers 排版容器
 * @param hasBacking 是否有背面
 * @param direction  绘制的方向
 * @param beforeDrawing 绘制前触发
 * @param tell 每绘制好一面时触发
 */
export function drawContainer(
  ctx: CanvasRenderingContext2D,
  op: ReturnType<typeof useScale>,
  containers: SideItem[][][],
  hasBacking: boolean,
  beforeDrawing: (side: 'front' | 'back') => void,
  tell: (fileName: string, side: 'front' | 'back') => Promise<unknown>
) {
  /** 一次绘制 */
  const draw = async (
    file: (File | undefined)[][],
    fileName: string,
    side: 'front' | 'back'
  ) => {
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height)
    ctx.save()
    beforeDrawing(side)
    await drawLayout(ctx, op.layout, op.drawArea, file)
    ctx.restore()
    await tell(fileName, side)
    ctx.restore()
  }

  let index = 1
  const loop = async () => {
    const container = containers.shift()
    if (!container) return void 0

    const fileIndex = index > 9 ? index : '0' + index
    const front =
      op.direction === 'col'
        ? container.map((row) => row.map((c) => c.front).reverse())
        : container.map((row) => row.map((c) => c.front))
    await draw(front, `${fileIndex}-正面.png`, 'front')

    // 如果需要绘制反面
    if (hasBacking) {
      const back =
        op.direction === 'col'
          ? container.map((row) => row.map((c) => c.back))
          : container.map((row) => row.map((c) => c.back).reverse())
      await draw(back, `${fileIndex}-反面.png`, 'back')
    }

    index++
    return await loop()
  }

  return loop()
}

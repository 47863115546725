import { FileUploader } from './service/FileUploader'
import { SyncHook } from './service/SyncHook'

/** 清空按钮 */
export const clearBtn = document.getElementById('clear-btn')!

/** 下载制作按钮 */
export const downloadBtn = document.getElementById('download-btn')!

/** 信息列表节点 */
export const infoContainer = document.getElementById('upload-info')!

/** 承载文件夹列表的节点 */
export const folderList = document.getElementById('folder-list')!

// 统计信息节点
export const counterEl = {
  folder: document.getElementById('folder-count')!,
  container: document.getElementById('container-count')!,
  total: document.getElementById('total-size')!,
  remainder: document.getElementById('remainder-count')!,
}

// 类型选择
export const typeEl = document.querySelector('#type')!

// 操作人员名称
export const nameEl = document.querySelector('#name') as HTMLInputElement

// 当前序号
export const numberEl = document.querySelector('#number') as HTMLInputElement

// 备注信息
export const noteEl = document.querySelector('#remarks') as HTMLTextAreaElement

/** 预览节点 */
export const previewCanvas = document.querySelector(
  '#canvas'
) as HTMLCanvasElement
export const perviewCtx = previewCanvas.getContext('2d')!

/** 画布节点 */
export const canvas = document.createElement('canvas') as HTMLCanvasElement
// 获取上下文环境
export const ctx = canvas.getContext('2d')!

/** 上传句柄 */
export const uploader = new FileUploader('dropzone')

/** 制作中的钩子 */
export const hookMake = {
  start: new SyncHook(),
  drawStart: new SyncHook<['front' | 'back']>(),
  drawIng: new SyncHook<[string, 'front' | 'back']>(),
  drawOver: new SyncHook<[string]>(),
  putFile: new SyncHook<[string]>(),
  savePart: new SyncHook(),
  over: new SyncHook(),
  fail: new SyncHook<[Error]>(),
}

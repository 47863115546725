import { canvas, previewCanvas, typeEl } from './define'
import postcard from './data/postcard.data.json'
import lesserCalorie from './data/lesserCalorie.data.json'
import { useScale } from './helper/useScale'
import { store } from './store'

const { searchParams } = new URL(window.location.href)

const typeList = [
  { name: '明信片', param: postcard },
  { name: '小卡', param: lesserCalorie },
]
const type = parseInt(searchParams.get('type') ?? '0')
const findType = typeList[type] ?? typeList[0]!

// 设置预览的画布宽高
previewCanvas.width = findType.param.width / 10
previewCanvas.height = findType.param.height / 10

// 获取绘制参数
const drawParam = useScale(findType.param)

// 设置排版画布的宽高
canvas.width = drawParam.width
canvas.height = drawParam.height

// 设置绘制参数
store.drawParam = drawParam

// 设置类型切换
typeList.forEach((item, index) => {
  const link = document.createElement('a')
  link.className = `px-4 py-2 rounded-md border border-gray-300 hover:bg-gray-50 active:bg-gray-100 transition-colors duration-200 cursor-pointer text-center`
  if (index === type) {
    link.className += ' selected-type'
  } else {
    link.href = `/?type=${index}`
  }

  link.innerText = item.name
  typeEl.appendChild(link)
})

import { infoContainer } from '../define'

export function addUploadInfo(
  message: string,
  type: 'info' | 'success' | 'error' | 'warning'
) {
  const infoElement = document.createElement('div')
  infoElement.className = 'mb-2'

  // 根据消息类型设置不同的样式
  switch (type) {
    case 'success':
      infoElement.className += ' text-green-600'
      break
    case 'error':
      infoElement.className += ' text-red-600'
      break
    case 'warning':
      infoElement.className += ' text-yellow-600'
      break
    default:
      infoElement.className += ' text-gray-600'
  }

  // 添加时间戳
  const timestamp = new Date().toLocaleTimeString()
  const timeElement = document.createElement('span')
  timeElement.className = 'text-xs text-gray-400 ml-2 mr-2'
  timeElement.textContent = `[${timestamp}]`
  infoElement.appendChild(timeElement)

  // 添加信息
  const info = document.createElement('span')
  info.textContent = message
  infoElement.appendChild(info)

  // 如果信息太多，删除最旧的
  if (infoContainer.children.length > 10) {
    infoContainer.removeChild(infoContainer.lastChild!)
  }

  infoContainer.insertBefore(infoElement, infoContainer.firstChild)
}

export function date(format: string): string {
  const now = new Date()

  const replacements: { [key: string]: string } = {
    y: String(now.getFullYear()).slice(-2), // 两位数年份
    Y: String(now.getFullYear()), // 四位数年份
    m: String(now.getMonth() + 1).padStart(2, '0'), // 月份
    d: String(now.getDate()).padStart(2, '0'), // 日期
    h: String(now.getHours()).padStart(2, '0'), // 小时
    i: String(now.getMinutes()).padStart(2, '0'), // 分钟
    s: String(now.getSeconds()).padStart(2, '0'), // 秒
  }

  return format.replace(/y|Y|m|d|h|i|s/g, (match) => replacements[match])
}

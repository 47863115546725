import './withFolderPrint'
import './withButton'
import './withTypes'
import { canvas, ctx, hookMake, perviewCtx, previewCanvas } from './define'
import { store } from './store'
import { ZipDownload } from './service/ZipDownload'
import { date } from './helper/date'
import { drawSubline } from './helper/drawSubline'
import { addUploadInfo } from './helper/addUploadInfo'
import { drawCornerText } from './helper/drawCornerText'
import { appendPositionPoint } from './helper/appendPositionPoint'

// 用户点击排版
hookMake.start.on(() => {
  const filename = `${store.data.name ?? '操作员'}-${date('m.d_h.i')}`
  store.zip = new ZipDownload(filename)
})

// 绘制开始前，检查绘制的方向
hookMake.drawStart.on((side) => {
  // 如果绘制方向发生颠倒
  if (store.drawParam.direction === 'col') {
    ctx.translate(canvas.width / 2, canvas.height / 2)
    ctx.rotate(Math.PI * (side === 'front' ? 1.5 : 0.5))
    ctx.translate(-canvas.width / 2, -canvas.height / 2)

    const diff = (canvas.width - canvas.height) / 2
    ctx.translate(diff, -diff + store.drawParam.drawArea.startY)
  }
})

// 生成预览, 添加预览
hookMake.drawIng.on((fileName) => {
  perviewCtx.clearRect(0, 0, previewCanvas.width, previewCanvas.height)
  perviewCtx.drawImage(canvas, 0, 0, previewCanvas.width, previewCanvas.height)

  addUploadInfo(`绘制 /${fileName}`, 'info')
})

// 已排版完成，绘制栅格, 绘制定点信息
hookMake.drawIng.on(() => {
  // 栅格
  drawSubline(
    ctx,
    store.drawParam.layoutRoate,
    store.drawParam.drawAreaRoate,
    2
  )

  // 绘制定点信息
  appendPositionPoint(
    ctx,
    store.drawParam.drawAreaRoate,
    store.drawParam.offset,
    store.drawParam.R
  )
})

// 写入备注信息以及编号
hookMake.drawIng.on((fileName, side) => {
  const input = ` [${date('m/d h:i:s')}] ${store.data.name}: ${store.data.note}`
  drawCornerText(ctx, side === 'back' ? 'topLeft' : 'topRight', {
    text: input,
    fontSize: store.drawParam.fontSize,
    offsetX: 0,
  })

  drawCornerText(ctx, side === 'back' ? 'bottomLeft' : 'bottomRight', {
    text: `序号: ${store.getCurrent()}${fileName.includes('反面') ? '+' : ''}`,
    color: 'red',
    fontSize: store.drawParam.fontSize * 1.2,
    offsetX: 0,
  })

  if (!store.drawParam.withBack || side === 'back') {
    store.nextCurrent()
  }
})

// 绘制完成， 正在保存
hookMake.drawOver.on((fileName) => {
  addUploadInfo(`保存 /${fileName}`, 'warning')
})

// 保存完毕
hookMake.putFile.on((fileName) => {
  addUploadInfo(`已完成 /${fileName}`, 'success')
})

// 压缩包过大时
hookMake.savePart.on(() => {
  addUploadInfo(`正在保存部分文件`, 'warning')
})

// 下载完毕
hookMake.over.on(() => {
  addUploadInfo(`已全部完成`, 'success')
})

// 发现错误时
hookMake.fail.on((err) => {
  addUploadInfo(err.message, 'error')
})

import JSZip from 'jszip'

export class ZipDownload {
  /**
   * 判断压缩包是否已经过大
   * @returns
   */
  isBlobSizeOver() {
    return this.currentSize > this.maxSize
  }

  /**
   * 添加文件到zip压缩包中
   * @param filename
   * @param data
   */
  put(filename: string, data: Blob | string) {
    this.zip.file(filename, data)
    if (data instanceof Blob) {
      this.currentSize += data.size
    }
  }

  /**
   * 保存当前压缩包
   */
  async save() {
    const blob = await this.zip.generateAsync({ type: 'blob' })

    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.download = `${this.filename}_${this.counter++}.zip`
    link.click()
    URL.revokeObjectURL(url)
    this.currentSize = 0
    this.zip = new JSZip()
  }

  constructor(
    public readonly filename: string,
    public readonly maxSize = 500 * 1024 * 1024
  ) {}
  public currentSize = 0
  protected counter = 0
  protected zip = new JSZip()
}

import { parseFileName } from '../helper/parseFileName'
import { uuidv4 } from '../helper/uuidv4'
import type { FileUploader } from './FileUploader'

export class FileTransform {
  /** 获取文件夹 */
  protected getFileNode(dirName: string) {
    const folder = this.dirMap.get(dirName)
    if (folder) return folder

    const newFolder: FileNode = {
      name: dirName,
      uuid: uuidv4(),
      type: 'directory',
      children: [],
    }

    this.dirMap.set(dirName, newFolder)
    return newFolder
  }

  /**
   * 添加文件到指定item
   * @param dirName
   * @param fileName
   * @param file
   */
  withItem(dirName: string, fileName: string, file: File) {
    const folder = this.getFileNode(dirName)
    const fileInfo = parseFileName(fileName)

    if (fileInfo === null) {
      this.uploadService.hook.fileMiss.notify(file, folder)
      return void 0
    }

    // 找到分组
    let group = folder.children.find(
      (g) =>
        fileInfo.group &&
        g.imageType === fileInfo.imageType &&
        g.name === fileInfo.group
    )

    // 不存在则创建
    if (!group) {
      group = {
        name: fileInfo.group,
        imageType: fileInfo.imageType,
        count: fileInfo.count,
        sides: [],
      }
      // 添加到文件夹中
      folder.children.push(group)
    }

    // 添加图片
    group.sides.push({
      side: fileInfo.side,
      file: file,
    })
  }

  /** 开始处理打包 */
  protected start() {
    for (const file of this.fileList) {
      const paths = file.webkitRelativePath.split(/\/|\\/)
      const fileName = paths.pop()
      const dirName = paths.pop()

      if (!dirName || !fileName) continue

      this.withItem(dirName, fileName, file)
    }
  }

  constructor(
    protected fileList: FileList,
    protected uploadService: FileUploader
  ) {
    this.start()
  }

  get nodes() {
    const nodes: FileNode[] = []

    this.dirMap.values().forEach((item) => nodes.push(item))

    return nodes
  }

  protected dirMap = new Map<string, FileNode>()
}

{
  "width": 1140,
  "height": 780,
  "drawArea": {
    "startX": 40,
    "startY": 0,
    "endX": 1100,
    "endY": 780
  },
  "layout": {
    "row": 5,
    "column": 10
  },
  "offset": {
    "x": 3,
    "y": 3
  },
  "scale": 11.814159292035399,
  "R": 6,
  "fontSize": 12,
  "direction": "row",
  "withBack": true
}

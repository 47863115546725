import {
  canvas,
  clearBtn,
  ctx,
  downloadBtn,
  hookMake,
  uploader,
} from './define'
import { addDpiToPng } from './helper/addDpiToPng'
import { addUploadInfo } from './helper/addUploadInfo'
import { buildContainer } from './helper/buildContainer'
import { catContainerDetail } from './helper/catContainerDetail'
import { drawContainer } from './helper/drawContainer'
import { flatFileNode } from './helper/flatFileNode'
import { store } from './store'

// 清空按钮点击事件
clearBtn.addEventListener('click', function () {
  if (store.downloadIng) {
    return addUploadInfo('有排版正在处理', 'warning')
  }

  window.location.href += ''
})

// 下载按钮点击事件
downloadBtn.addEventListener('click', async () => {
  if (store.downloadIng) return void 0
  store.downloadIng = true
  downloadBtn.textContent = '排版制作中'
  hookMake.start.notify()
  try {
    // 实现下载功能的代码
    const folderList = uploader.folderStructure
    if (folderList.length === 0) {
      throw new Error('文件夹为空')
    }

    // 排版文件
    const containers = buildContainer(
      flatFileNode(folderList),
      store.drawParam.layout
    )

    // 保存操作文本记录
    const cat = catContainerDetail(containers, true)
    store.zip.put(`${store.zip.filename}.csv`, cat)

    // 绘制
    await drawContainer(
      ctx,
      store.drawParam,
      containers,
      store.drawParam.withBack,
      (side) => hookMake.drawStart.notify(side),
      async (fileName, side) => {
        // 绘制中...
        hookMake.drawIng.notify(fileName, side)

        // 保存图片信息，这一步特别久！
        const blob = await new Promise<Blob | null>((res) => canvas.toBlob(res))
        hookMake.drawOver.notify(fileName)

        // 保存到zip文件夹
        const dpiBlob = await addDpiToPng(blob!, 300)
        store.zip.put(fileName, dpiBlob)
        hookMake.putFile.notify(fileName)

        // 如果当前压缩包过大时，先进行保存
        if (store.zip.isBlobSizeOver()) {
          hookMake.savePart.notify()
          await store.zip.save()
        }
      }
    )

    // 下载
    if (store.zip.currentSize !== 0) {
      await store.zip.save()
      hookMake.over.notify()
    }
  } catch (err: any) {
    hookMake.fail.notify(err)
  }

  store.downloadIng = false
  downloadBtn.textContent = '制作排版'
})

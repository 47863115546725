/**
 * 使用绘制参数的缩放属性重新打包数据
 * @param param
 * @returns
 */
export function useScale(param: DrawParam) {
  const {
    drawArea,
    layout,
    offset,
    R,
    scale,
    width,
    height,
    direction,
    fontSize,
    withBack,
  } = param

  const result = {
    width: width * scale,
    height: height * scale,
    drawArea: {
      startX: drawArea.startX * scale,
      startY: drawArea.startY * scale,
      endX: drawArea.endX * scale,
      endY: drawArea.endY * scale,
    },
    drawAreaRoate: {
      startX: drawArea.startX * scale,
      startY: drawArea.startY * scale,
      endX: drawArea.endX * scale,
      endY: drawArea.endY * scale,
    },
    layout,
    layoutRoate: layout,
    offset: {
      x: offset.x * scale,
      y: offset.y * scale,
    },
    R: R * scale,
    direction,
    fontSize: fontSize * scale,
    scale,
    withBack,
  }

  // 如果发生方向颠倒
  if (direction === 'col') {
    result.drawAreaRoate = {
      startX: result.drawArea.startY,
      startY: result.drawArea.startX,
      endX: result.drawArea.endY,
      endY: result.drawArea.endX,
    }

    result.layoutRoate = {
      row: layout.column,
      column: layout.row,
    }
  }

  return result
}

/**
 * 添加dpi信息到画布
 * @param canvas
 * @param dpi
 */
export function addDpiToPng(blob: Blob, dpi: number) {
  return new Promise<Blob>((resolve) => {
    // 创建 FileReader 以读取 blob 数据
    const reader = new FileReader()
    reader.onload = function () {
      const buffer = this.result as ArrayBuffer
      // const view = new DataView(buffer)

      // PNG 签名 (8 bytes) + IHDR chunk (25 bytes)
      const pHYsChunkStart = 33

      // 创建新的 ArrayBuffer，为 pHYs chunk 预留空间
      const newBuffer = new ArrayBuffer(buffer.byteLength + 21)
      const newView = new DataView(newBuffer)

      // 复制 PNG 签名和 IHDR chunk
      new Uint8Array(newBuffer).set(
        new Uint8Array(buffer.slice(0, pHYsChunkStart))
      )

      // 写入 pHYs chunk
      newView.setUint32(pHYsChunkStart, 9) // Length of pHYs data
      newView.setUint32(pHYsChunkStart + 4, 0x70485973) // "pHYs" in ASCII
      newView.setUint32(pHYsChunkStart + 8, dpi * 39.37) // Pixels per unit, X axis (39.37 in/m)
      newView.setUint32(pHYsChunkStart + 12, dpi * 39.37) // Pixels per unit, Y axis
      newView.setUint8(pHYsChunkStart + 16, 1) // Unit specifier (1 = meters)

      // 计算 CRC
      const crc = crc32(
        new Uint8Array(newBuffer.slice(pHYsChunkStart + 4, pHYsChunkStart + 17))
      )
      newView.setUint32(pHYsChunkStart + 17, crc)

      // 复制剩余的图像数据
      new Uint8Array(newBuffer).set(
        new Uint8Array(buffer.slice(pHYsChunkStart)),
        pHYsChunkStart + 21
      )

      // 创建新的 Blob 并解析
      const newBlob = new Blob([newBuffer], { type: 'image/png' })
      resolve(newBlob)
    }
    reader.readAsArrayBuffer(blob!)
  })
}

// CRC32 function
function crc32(data: Uint8Array): number {
  let crc = -1
  for (let i = 0; i < data.length; i++) {
    crc = (crc >>> 8) ^ crcTable[(crc ^ data[i]) & 0xff]
  }
  return (crc ^ -1) >>> 0
}

// CRC32 table
const crcTable = new Uint32Array(256)
for (let i = 0; i < 256; i++) {
  let c = i
  for (let j = 0; j < 8; j++) {
    c = c & 1 ? 0xedb88320 ^ (c >>> 1) : c >>> 1
  }
  crcTable[i] = c
}

/**
 * 排版容器
 * @param containers
 */
export function catContainerDetail(
  containers: SideItem[][][],
  hasBacking: boolean
) {
  const result: Array<string> = [['排版文件', '文件夹', '图片'].join(',')]

  let index = 1
  containers.forEach((container) => {
    const fileIndex = index > 9 ? index : '0' + index

    result.push(`${fileIndex}-正面.png,,,`)
    container.forEach((rows) => {
      rows.forEach((row) => {
        result.push(
          ['', row.folderName, row.front?.name ?? '空白填充'].join(',')
        )
      })
    })

    if (hasBacking) {
      result.push(`${fileIndex}-反面.png,,,`)
      container.forEach((rows) => {
        rows.forEach((row) => {
          result.push(
            ['', row.folderName, row.back?.name ?? '空白填充'].join(',')
          )
        })
      })
    }

    index++
    result.push(',,,')
  })

  return result.join('\n')
}

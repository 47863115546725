/**
 * 解码文件名
 * @param filename
 */
export function parseFileName(filename: string): {
  group: string
  imageType: 'product' | 'promotional'
  side: 'front' | 'back'
  count: number
} | null {
  const productRegex = /^(.*?)-(正面|反面)x\[(\d+)张\]\.(png|jpe?g)$/i
  const promotionalRegex = /^(.*?)-宣传图(正面|反面)x\[(\d+)张\]\.(png|jpe?g)$/i

  let match = filename.match(productRegex)
  if (match) {
    return {
      group: match[1],
      imageType: 'product',
      side: match[2] === '正面' ? 'front' : 'back',
      count: parseInt(match[3]),
    }
  }

  match = filename.match(promotionalRegex)
  if (match) {
    return {
      group: match[1],
      imageType: 'promotional',
      side: match[2] === '正面' ? 'front' : 'back',
      count: parseInt(match[3]),
    }
  }

  return null
}

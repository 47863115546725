import { folderList, uploader } from '../define'

export function addFolderToList(f: FileNode) {
  const list = folderList
  const item = document.createElement('li')
  item.className = 'flex justify-between items-start bg-gray-100 p-2 rounded'
  item.innerHTML = `
      <p class="text-sm">${f.name}</p>
      <button class="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded text-xs delete-folder flex-shrink-0">
        删除
      </button>
    `
  list.appendChild(item)

  // 为删除按钮添加事件监听器
  item.querySelector('.delete-folder')!.addEventListener('click', function () {
    // 在这里添加删除文件夹的逻辑
    item.remove()
    uploader.remove(f)
  })
}

const cacheMap = new Map<File, Promise<HTMLImageElement>>()

/**
 * 传入file对象生成一张图片
 * @param file
 */
export function loadImage(file: File) {
  const cacheImage = cacheMap.get(file)
  if (cacheImage) return cacheImage

  const image = new Promise<HTMLImageElement>((resolve) => {
    const img = new Image()
    img.onload = () => resolve(img)
    img.src = URL.createObjectURL(file)
  })

  cacheMap.set(file, image)
  return image
}

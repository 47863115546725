/**
 * 创建卡片
 * @param group
 * @param folderName
 */
function touchSide(group: ImageGroup, folderName: string) {
  const front = group.sides.find((s) => s.side === 'front')
  const back = group.sides.find((s) => s.side === 'back')

  return new Array(group.count).fill({
    folderName,
    name: group.name,
    front: front?.file,
    back: back?.file,
  }) as SideItem[]
}

/**
 * 拉平文件夹列表结构
 * @param nodes
 */
export function flatFileNode(nodes: FileNode[]) {
  return nodes
    .map((node) => {
      const { name, children } = node

      // 宣传图
      const promotional = children.find((c) => c.imageType === 'promotional')

      // 产品列表
      const products = children.filter((c) => c.imageType === 'product')

      return promotional
        ? [
            products.map((g) => touchSide(g, name)).flat(),
            touchSide(promotional, name),
          ].flat()
        : products.map((g) => touchSide(g, name)).flat()
    })
    .flat() as SideItem[]
}

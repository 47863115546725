interface TextConfig {
  text: string
  fontSize?: number
  offsetX?: number
  offsetY?: number
  fontFamily?: string
  color?: string
  padding?: number
  lineHeight?: number
}

type Corner = 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight'

export function drawCornerText(
  ctx: CanvasRenderingContext2D,
  corner: Corner,
  config: TextConfig
): void {
  const canvas = ctx.canvas

  const {
    text,
    fontSize = 16,
    fontFamily = 'Arial',
    color = '#000000',
  } = config

  const offsetX = config.offsetX ?? fontSize * 2
  const offsetY = config.offsetY ?? fontSize * 2

  // 设置字体样式
  ctx.font = `${fontSize}px ${fontFamily}`
  ctx.fillStyle = color

  const measureText = ctx.measureText(text)

  ctx.save()

  // 根据不同角落调整位置和方向
  switch (corner) {
    case 'topLeft':
      ctx.translate(fontSize + offsetX, measureText.width + fontSize + offsetY)
      ctx.rotate(Math.PI / -2)
      break
    case 'topRight':
      ctx.translate(canvas.width - fontSize - offsetX, fontSize + offsetY)
      ctx.rotate(Math.PI / 2)
      break
    case 'bottomLeft':
      ctx.translate(fontSize + offsetX, canvas.height - offsetY)
      ctx.rotate(Math.PI / -2)
      break
    case 'bottomRight':
      ctx.translate(
        canvas.width - fontSize - offsetX,
        canvas.height - measureText.width - offsetY
      )
      ctx.rotate(Math.PI / 2)
      break
  }

  ctx.fillText(text, 0, 0)
  ctx.restore()
}

import { parseFileName } from '../helper/parseFileName'
import { uuidv4 } from '../helper/uuidv4'
import { FileTransform } from './FileTransform'
import { SyncHook } from './SyncHook'

export class FileUploader {
  /** 是否为文件夹 */
  private isDirectory(input: any): input is FileSystemDirectoryEntry {
    if (input === null) return false
    try {
      return input.isDirectory
    } catch {
      return false
    }
  }

  /** 是否为文件 */
  private isFile(input: any): input is FileSystemFileEntry {
    if (input === null) return false
    try {
      return input.isFile
    } catch {
      return false
    }
  }

  /** 添加input上传文件夹 */
  private initializeInputHandle() {
    const input = document.createElement('input')
    input.type = 'file'
    input.webkitdirectory = true // 允许选择整个文件夹
    input.multiple = true
    this.dropzone.appendChild(input)
    input.addEventListener('change', () => {
      if (input.files) {
        this.hanldeTouchUpload(input.files)
        this.hook.folderChange.notify(this.folderStructure)
      }
    })
  }

  /** 用户点击上传 */
  private hanldeTouchUpload(fileList: FileList) {
    const fileTransform = new FileTransform(fileList, this)
    const folders = fileTransform.nodes
    folders.forEach((folder) => {
      if (this.uploadedFolders.has(folder.name)) {
        return this.hook.folderRepeat.notify(folder)
      }

      // 如果不为空，则进行添加
      if (folder.children.length) {
        this.uploadedFolders.add(folder.name)
        this.folderStructure.push(folder)
        this.hook.folderPush.notify(folder)
      } else {
        this.hook.folderMiss.notify(folder)
      }
    })
  }

  /** 初始化事件 */
  private initializeEventListeners(): void {
    this.dropzone.addEventListener('dragover', this.handleDragOver.bind(this))
    this.dropzone.addEventListener('drop', this.handleDrop.bind(this))
  }

  /** 拖拽结束 */
  private handleDragOver(event: DragEvent): void {
    event.preventDefault()
    event.stopPropagation()
  }

  /** 拖拽释放 */
  private async handleDrop(event: DragEvent): Promise<void> {
    event.preventDefault()
    event.stopPropagation()

    const items = event.dataTransfer?.items
    if (!items) return

    for (let i = 0; i < items.length; i++) {
      const item = items[i].webkitGetAsEntry()
      if (this.isDirectory(item)) {
        await this.processDirectory(item)
      }
    }

    this.hook.folderChange.notify(this.folderStructure)
  }

  /** 处理文件夹信息 */
  private async processDirectory(
    entry: FileSystemDirectoryEntry
  ): Promise<void> {
    if (this.uploadedFolders.has(entry.name)) {
      return this.hook.folderRepeat.notify(entry)
    }

    const dirReader = entry.createReader()

    const touchDir = await new Promise<FileNode>((resolve) => {
      const touchDir: FileNode = {
        name: entry.name,
        uuid: uuidv4(),
        type: 'directory',
        children: [],
      }

      this.hook.folderTouch.notify(touchDir)

      dirReader.readEntries(async (entries: FileSystemEntry[]) => {
        for (const entry of entries) {
          if (this.isFile(entry)) {
            await this.processFile(entry, touchDir)
          } else if (this.isDirectory(entry)) {
            await this.processDirectory(entry)
          }
        }

        resolve(touchDir)
      })
    })

    // 如果不为空，则进行添加
    if (touchDir.children.length) {
      this.uploadedFolders.add(touchDir.name)
      this.folderStructure.push(touchDir)
      this.hook.folderPush.notify(touchDir)
    } else {
      this.hook.folderMiss.notify(touchDir)
    }
  }

  /**
   * 添加文件到具体的文件夹中
   * @param entry
   * @param parent
   * @returns
   */
  private async processFile(
    entry: FileSystemFileEntry,
    parent: FileNode
  ): Promise<void> {
    const file = await new Promise<File>((res) => entry.file(res))
    const fileInfo = parseFileName(file.name)
    if (fileInfo === null) {
      this.hook.fileMiss.notify(file, parent)
      return void 0
    }

    // 找到分组
    let group = parent.children.find(
      (g) =>
        fileInfo.group &&
        g.imageType === fileInfo.imageType &&
        g.name === fileInfo.group
    )

    // 不存在则创建
    if (!group) {
      group = {
        name: fileInfo.group,
        imageType: fileInfo.imageType,
        count: fileInfo.count,
        sides: [],
      }
      // 添加到文件夹中
      parent.children.push(group)
    }

    // 添加图片
    group.sides.push({
      side: fileInfo.side,
      file: file,
    })
  }

  /** 清空文件夹 */
  clear() {
    this.folderStructure.splice(0, this.folderStructure.length)
    this.uploadedFolders.clear()
    this.hook.folderChange.notify(this.folderStructure)
  }

  /** 删除具体的文件夹 */
  remove(f: FileNode) {
    const index = this.folderStructure.findIndex((item) => item.uuid === f.uuid)
    if (index > -1) {
      this.uploadedFolders.delete(f.name)
      this.hook.folderRemvoe.notify(this.folderStructure[index])
      this.folderStructure.splice(index, 1)
    }
    this.hook.folderChange.notify(this.folderStructure)
  }

  constructor(dropzoneId: string) {
    this.dropzone = document.getElementById(dropzoneId) as HTMLElement

    this.initializeInputHandle()
    this.initializeEventListeners()
  }

  private dropzone: HTMLElement
  private uploadedFolders: Set<string> = new Set()
  public readonly folderStructure: FileNode[] = []
  public readonly hook = {
    folderTouch: new SyncHook<[FileNode]>(), // 尝试添加新的文件夹
    folderPush: new SyncHook<[FileNode]>(), // 添加新的文件夹
    folderRemvoe: new SyncHook<[FileNode]>(), // 有文件夹被删除
    folderChange: new SyncHook<[FileNode[]]>(), // 文件夹列表有改变
    folderMiss: new SyncHook<[FileNode]>(), // 文件夹被跳过
    folderRepeat: new SyncHook<[FileSystemDirectoryEntry | FileNode]>(), // 重复的文件夹
    fileMiss: new SyncHook<[File, FileNode]>(), // 被跳过的文件
  }
}

type ArcArgs = Parameters<CanvasRenderingContext2D['arc']>

/**
 * 传入canvas绘制环境，根据 area 在canvas上下左右4个角绘制黑色的居中实心圆
 * @param ctx 绘制环境
 * @param area 一角区域大小
 * @param r 实心圆大小
 */
export function appendPositionPoint(
  ctx: CanvasRenderingContext2D,
  area: {
    startX: number
    startY: number
    endX: number
    endY: number
  },
  offset: {
    x: number
    y: number
  },
  R: number
) {
  const r = R / 2
  const offsetX = R + offset.x
  const { startX, startY, endX, endY } = area

  // 设置填充颜色为黑色
  ctx.fillStyle = 'black'

  const points: Array<ArcArgs> = []

  // 左上角
  points.push([startX - offsetX + r, startY + offset.y + r, r, 0, Math.PI * 2])
  // 右上角
  points.push([endX + offset.x + r, startY + offset.y + r, r, 0, Math.PI * 2])
  // 左下角
  points.push([startX - offsetX + r, endY - r - offset.y, r, 0, Math.PI * 2])
  // 右下角
  points.push([endX + offset.x + r, endY - r - offset.y, r, 0, Math.PI * 2])

  // 绘制
  points.forEach(([x, y, radius, startAngle, endAnele]) => {
    ctx.beginPath()
    ctx.arc(x, y, radius, startAngle, endAnele)
    ctx.fill()
  })
}

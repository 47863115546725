import { uploader, counterEl } from './define'
import { addFolderToList } from './helper/addFolderToList'
import { addUploadInfo } from './helper/addUploadInfo'
import { updateStats } from './helper/updateStats'

// 有文件被跳过
uploader.hook.fileMiss.on((file, folder) => {
  addUploadInfo(
    `文件 "${folder.name}/${file.name}" 格式不正确，已跳过`,
    'warning'
  )
})

// 文件夹被上传
uploader.hook.folderTouch.on((folder) => {
  addUploadInfo(`文件夹正在处理 [${folder.name}]`, 'info')
})

// 空的文件夹
uploader.hook.folderMiss.on((folder) => {
  addUploadInfo(`文件夹 [${folder.name}] 不包含文件，已跳过`, 'error')
})

// 重复添加的文件夹
uploader.hook.folderRepeat.on((folder) => {
  addUploadInfo(`文件夹 [${folder.name}] 已存在`, 'warning')
})

// 文件夹上传成功
uploader.hook.folderPush.on((folder) => {
  addFolderToList(folder)
  addUploadInfo(`文件夹已上传 [${folder.name}]`, 'success')
})

// 文件夹删除
uploader.hook.folderRemvoe.on((folder) => {
  addUploadInfo(`文件夹已删除 [${folder.name}]`, 'warning')
})

// 文件夹发生变化
uploader.hook.folderChange.on((folderList) => {
  const counter = updateStats(folderList, 50)
  counterEl.folder.textContent = counter.folder.toString()
  counterEl.total.textContent = `${counter.total} 张`
  counterEl.container.textContent = `${counter.container} 张`
  counterEl.remainder.textContent = `${counter.remainder} 张`
})

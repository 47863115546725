import postcard from './data/postcard.data.json'
import { nameEl, noteEl, numberEl } from './define'
import { date } from './helper/date'
import { useScale } from './helper/useScale'
import { ZipDownload } from './service/ZipDownload'

const localMarkKey = `mark-index-${date('y/m/d')}`

export const store = {
  drawParam: useScale(postcard),
  downloadIng: false,
  zip: new ZipDownload(date('y年m月d日h:i')),
  data: {
    number: window.localStorage.getItem(localMarkKey) ?? '1',
    name: window.localStorage.getItem('mark-name') ?? '', // 人员信息
    note: '', // 备注信息
  },

  // 获取当前排版序号
  getCurrent() {
    return parseInt(this.data.number)
  },

  // 当前序号+1
  nextCurrent() {
    const current = this.getCurrent()
    const val = (current + 1).toString()
    this.data.number = val
    window.localStorage.setItem(localMarkKey, val)
  },
}

numberEl.value = store.data.number
numberEl.onchange = () => {
  const val = numberEl.value.trim()
  store.data.number = val
  window.localStorage.setItem(localMarkKey, val)
}

nameEl.value = store.data.name
nameEl.onchange = () => {
  const val = nameEl.value.trim()
  store.data.name = val
  window.localStorage.setItem('mark-name', val)
}

noteEl.onchange = () => {
  const val = noteEl.value.trim()
  store.data.note = val
}
